import classNames from 'classnames';
import * as React from 'react';

export interface IProps {
    pageNumber: number;
    selected: boolean;
    onPageSelect: (pageNumber: number) => void;
}

export class PageItem extends React.PureComponent<IProps> {
    public render() {
        const { pageNumber, selected } = this.props;
        const classes = classNames('page-item', {
            'ml-2': pageNumber === 0,
            active: selected,
        });

        return (
            <li className={classes}>
                <button
                    type="button"
                    className="page-link"
                    onClick={this.handlePageSelect}
                >
                    {pageNumber + 1}
                </button>
            </li>
        );
    }

    private handlePageSelect = () => {
        this.props.onPageSelect(this.props.pageNumber);
    };
}
