import { logTechnical, PerformanceMeasureLogger } from '../logging/logger';
import { getSGConnect } from '../sgConnect';

export type Verb = 'GET' | 'POST' | 'PUT' | 'DELETE';

export async function fetcher<T>(
    url: string,
    verb: Verb,
    body?: object,
    hasBodyResponse: boolean = true
): Promise<T> {
    const jsonBody = body ? JSON.stringify(body) : undefined;

    const headers: string[][] = [
        ['accept', 'application/json'],
        ['content-type', 'application/json'],
    ];

    const perf = PerformanceMeasureLogger.start('web-http-client', url, {
        clientRequestBody: jsonBody || '',
        clientRequestMethod: verb,
    });

    const token = getSGConnect().getAuthorizationHeader();
    if (token) {
        headers.push(['authorization', token]);
    }

    try {
        const response = await fetch(url, {
            headers,
            method: verb,
            body: jsonBody,
        });

        if (response.ok && hasBodyResponse) {
            const dataResponse = (await response.json()) as T;
            return dataResponse;
        }

        if (response.ok && !hasBodyResponse) {
            return {} as T;
        }

        throw new HttpError(
            'an error occured during HTTP request',
            url,
            response.status,
            jsonBody,
            await getResponseErrorContent(response)
        );
    } catch (error) {
        if (error instanceof HttpError) {
            if (error.statusCode >= 500) {
                logTechnical('error', error.message, {
                    url: error.url,
                    statusCode: error.statusCode,
                    body: error.body || '',
                    stack: error.stack || '',
                });
            }

            if (error.statusCode === 403) {
                window.location.assign('/error403');
            }
        } else if (error instanceof Error) {
            logTechnical('error', error.message, {
                url,
                verb,
                body: JSON.stringify(body),
                hasBodyResponse,
                stack: error.stack || '',
            });
        }

        throw error;
    } finally {
        perf.stop();
    }
}

export async function sendForm<T>(
    url: string,
    verb: Verb,
    body: string
): Promise<T> {
    const headers: string[][] = [['accept', 'application/json'], ['Content-Type', 'application/json']];

    const perf = PerformanceMeasureLogger.start('web-http-client', url, {
        clientRequestBody: body,
        clientRequestMethod: verb,
    });

    const token = getSGConnect().getAuthorizationHeader();
    if (token) {
        headers.push(['authorization', token]);
    }

    try {
        const response = await fetch(url, {
            headers,
            method: verb,
            body,
        });
        const dataResponse = (await response.json()) as T;
        if (response.ok) {
            return dataResponse;
        }
        throw new HttpError(
            'an error occured during HTTP request',
            url,
            response.status,
            undefined,
            dataResponse
        );
    } catch (error) {
        if (error instanceof HttpError) {
            if (error.statusCode >= 500) {
                logTechnical('error', error.message, {
                    url: error.url,
                    statusCode: error.statusCode,
                    body: error.body || '',
                    stack: error.stack || '',
                });
            }
        } else if (error instanceof Error) {
            logTechnical('error', error.message, {
                url,
                verb,
                body: JSON.stringify(body),
                stack: error.stack || '',
            });
        }

        throw error;
    } finally {
        perf.stop();
    }
}

async function getResponseErrorContent(response: Response): Promise<any> {
    try {
        return await response.json();
    } catch (error) {
        try {
            return await response.text();
        } catch (error) {
            return undefined;
        }
    }
}

export async function downloader(
    url: string,
    verb: Verb
): Promise<DownloadedFile> {
    const headers: string[][] = [
        ['accept', 'application/json'],
        ['content-type', 'application/json'],
    ];

    const perf = PerformanceMeasureLogger.start('web-http-client', url, {
        clientRequestBody: '',
        clientRequestMethod: verb,
    });

    const token = getSGConnect().getAuthorizationHeader();
    if (token) {
        headers.push(['authorization', token]);
    }

    try {
        const response = await fetch(url, {
            headers,
            method: verb,
        });

        if (response.ok) {
            const name = getFileName(response);
            const content = await response.blob();

            return {
                name,
                content,
            };
        }

        throw new HttpError(
            'an error occured during HTTP request',
            url,
            response.status,
            undefined,
            await response.text()
        );
    } catch (error) {
        if (error instanceof HttpError) {
            logTechnical('error', error.message, {
                url: error.url,
                statusCode: error.statusCode,
                body: error.body || '',
                stack: error.stack || '',
            });
            if (error.statusCode === 403) {
                window.location.assign('/error403');
            }
        } else if (error instanceof Error) {
            logTechnical('error', error.message, {
                url,
                verb,
                stack: error.stack || '',
            });
        }

        throw error;
    } finally {
        perf.stop();
    }
}

const getFileName = (response: Response): string | null => {
    const contentDisposition = response.headers.get('content-disposition');
    if (!contentDisposition) {
        logTechnical(
            'warn',
            'Cannot retrieve content-disposition header. So filename is not set.',
            {
                url: response.url,
            }
        );
        return null;
    }

    const fileNames = contentDisposition
        .split(';')
        .filter(c => c.indexOf('filename=') >= 0);

    if (fileNames.length >= 1) {
        logTechnical(
            'warn',
            'Cannot extract filename from content-disposition',
            {
                url: response.url,
            }
        );
        return null;
    }

    const fileNameAttribute = fileNames[0].split('=');
    if (fileNameAttribute.length !== 2) {
        logTechnical(
            'warn',
            'Cannot retrieve filename from content-disposition',
            {
                url: response.url,
            }
        );
        return null;
    }

    return fileNameAttribute[1];
};

export type DownloadedFile = {
    name: string | null;
    content: Blob;
};

export class HttpError extends Error {
    public constructor(
        public readonly message: string,
        public readonly url: string,
        public readonly statusCode: number,
        public readonly body: string | undefined,
        public readonly content: any
    ) {
        super(message);
    }
}
