import * as React from 'react';
import { CreateAuditPageContext } from '../../contexts/CreateAuditPageContext';
import { map } from 'lodash';
import Topics from '../../../core/models/criteres.json';
import { Loading } from '../../loading/Loading';
import { AuditMetadata } from '../../../core/models/auditModels';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'ic-sgb4-simple-contact-picker': any;
            'ic-sgb4-simple-contacts-picker': any;
        }
    }
}

export const EditAuditForm = () => {
    const context = React.useContext(CreateAuditPageContext);
    
    function findArrayElementByTitle(array: any, title: string) {
        return array.testResult.find((element: any) => {
          return element.id === title;
        })
      }
      function formatResult(elt: any) {
        if (elt != undefined)  
            return elt.result;
        return "NotFound";
      }
      function splitContext(elt: any) {
        if (elt == null || elt == undefined || elt.context == null || elt.context == undefined)  
            return [];
        return elt.context.split("£");
      }

      function changeResult(elt: any, page: AuditMetadata, rule: string) {
        elt = "Error";
        page.testResult.map((test, index) => {
            if (test.id === rule) {
                if (test.result === "ManualTesting")
                    test.result = "Error"
                else if (test.result === "Error")
                    test.result = "OK or N/A"
                else
                    test.result = "ManualTesting"
            }
        });
        context.updateRule(page, rule, elt);
      }
      function GetIcon(props: any): any {
        const elt=props.elt;
        const page=props.page;
        const rule=props.rule;
          if (elt === "ManualTesting") {
              return <div style={{ flexShrink: 0, height: '56px', width: '56px'}} onClick={(event: React.MouseEvent<HTMLElement>) => {changeResult(elt, page, rule)}} className="border border-secondary bg-secondary-alpha-sm ml-5 d-flex flex-center" title='Manual Testing'><span className="d-flex flex-center text-xxlarge text-secondary">?</span></div>
          }
          if (elt === "OK or N/A") {
            return <div style={{ flexShrink: 0, height: '56px', width: '56px'}}  onClick={(event: React.MouseEvent<HTMLElement>) => {changeResult(elt, page, rule)}} className="border border-success bg-success-alpha-sm ml-5 d-flex flex-center" title='N/A or Ok'><span className="d-flex flex-center icon icon-md text-success">check</span></div>
          }
        return <div style={{ flexShrink: 0, height: '56px', width: '56px'}} onClick={(event: React.MouseEvent<HTMLElement>) => {changeResult(elt, page, rule)}}  className="border border-danger bg-danger-alpha-sm ml-5 d-flex flex-center" title='Ko'><span className="d-flex flex-center icon icon-md text-danger">close</span></div>
      }
      function GetContext(props: any): any {
        const elt=props.elt;
        const ctx=props.ctx;
          if (elt === "ManualTesting" && splitContext(ctx).length > 0) {
              return <li className="rule p-4 border-bottom border-light w-100 align-items-center ">
                  {
                       map(splitContext(ctx), (data, i) => (
                           data.length > 0 ?
                            <div className="col-12 m-1 p-3 html-code">{data}</div>
                            : ""
                       ))
                  }
                        
                    </li>
          }
          return <li className="rule p-0 border-bottom border-light d-flex w-100 align-items-center"></li>
        }


    return (
        <div>
            <form onSubmit={context.handleSubmit}>
                <div className="row">
                    <div className="col-8">
                        <h4 className="mb-3 mt-3">
                            <span>Audit Result</span>
                        </h4>
                    </div>
                    <div className="col-2">
                        <button type="submit" onClick={() => (context.submitAction(1))} value="Compute" className="btn btn-warning float-right">Compute Audit</button>
                    </div>
                    <div className="col-2">
                        <button type="submit" onClick={() => (context.submitAction(2))} value="Save" className="btn btn-success float-right">Save Audit</button>
                    </div>
                </div>
                
        {context.page !== undefined ? (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {context.isLoading ? (
                            context.isComputed ? <Loading /> : <div className="spinner-grow" role="status">Audit Scan in Progress.</div>
                        ) : (
                            <>
                            <main className="container mt-2">
                            <div className="row">
                                <form className="col col-12">
                                <ul className="list-unstyled">
                                {
                                    map(Topics.topics, (topic, i) => (
                                        <li className="shadow-lg mb-5 py-4 "><h2 className="m-4">{ topic.number }. { topic.topic }</h2>
                                            <ul className="rules list-unstyled">
                                                {
                                            map(topic.criteria, (criteria: any, j) => {
                                                return <>
                                                        <li className="rule p-4 d-flex w-100 align-items-center ">
                                                            <a className="text-link ml-3 mr-5 d-block text-nowrap" target="_blank" href={`https://www.numerique.gouv.fr/publications/rgaa-accessibilite/methode-rgaa/criteres/index.html#crit-${ topic.number }-${ criteria.criterium.number }`}>{ topic.number }-{ criteria.criterium.number }</a> 
                                                            <div className="text-large flex-grow-1">
                                                                <div className="text-xlarge">
                                                                    <p>{ criteria.criterium.title }</p>
                                                                </div> 
                                                                <span className="error-wrapper mt-2 text-danger "></span>
                                                            </div>
                                                            {
                                                                <GetIcon page={context.page} rule={`${ topic.number }.${ criteria.criterium.number }`} ctx={(findArrayElementByTitle(context.page, `${ topic.number }.${ criteria.criterium.number }`))} elt={(formatResult(findArrayElementByTitle(context.page, `${ topic.number }.${ criteria.criterium.number }`)))} />
                                                            }
                                                        </li>
                                                        
                                                        {
                                                            <GetContext page={context.page} rule={`${ topic.number }.${ criteria.criterium.number }`} ctx={(findArrayElementByTitle(context.page, `${ topic.number }.${ criteria.criterium.number }`))} elt={(formatResult(findArrayElementByTitle(context.page, `${ topic.number }.${ criteria.criterium.number }`)))} />
                                                        }
                                                        </>
                                            }
                                            )
}
                                            </ul>
                                        </li>
                                    ))
                                }
                                </ul>
                                </form>
                            </div>
                            </main>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>): (<span></span>)}
            </form>
        </div>
    );
};

