import * as React from 'react';
import { SearchAuditsContext } from '../../../contexts/SearchAuditsContext';
import { AuditCard } from './AuditCard';
import { map } from 'lodash';
import { Loading } from '../../../loading/Loading';
import { Pager } from '../../../common/pagination/Pager';

export const SearchLayout = () => {
    const context = React.useContext(SearchAuditsContext);

    const callback = React.useCallback(
        (event: any) => {
            context.updateSearch(event.currentTarget.value);
        },
        [context]
    );

    return (
        <div className="pt-5">
            <div className="container">
                <div className="input-group input-group-xl">
                    <div className="input-group-prepend input-group-merged">
                        <i className="icon text-secondary">search</i>
                    </div>
                    <input
                        type="searchText"
                        className="form-control form-control-xl"
                        placeholder="Search for Audits"
                        onChange={callback}
                        value={context.search}
                    />
                </div>
                <div className="row mt-5">
                    <div className="col-12 col-md-12">
                        {context.isLoading ? (
                            <Loading />
                        ) : (
                            <>
                                <Pager
                                    skip={context.skip}
                                    take={context.take}
                                    totalItems={context.count}
                                    onPageChange={(
                                        skip: number,
                                        take: number
                                    ) => {
                                        context.updatePager(skip);
                                    }}
                                />
                                <div className="row d-flex">
                                    {map(
                                        context.Audits,
                                        (Audit, index) => (
                                            <AuditCard
                                                Audit={Audit}
                                                key={index}
                                            />
                                        )
                                    )}
                                </div>
                                <Pager
                                    skip={context.skip}
                                    take={context.take}
                                    totalItems={context.count}
                                    onPageChange={(
                                        skip: number,
                                        take: number
                                    ) => {
                                        context.updatePager(skip);
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
