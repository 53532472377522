import * as React from 'react';
import { CreateAuditContext } from '../../contexts/CreateAuditContext';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'ic-sgb4-simple-contact-picker': any;
            'ic-sgb4-simple-contacts-picker': any;
        }
    }
}

export const EditCreateAuditForm = () => {
    const context = React.useContext(CreateAuditContext);

    const setWritersMountedElement = React.useCallback(
        (mountedElement: HTMLElement) => {
            if (mountedElement) {
                mountedElement.addEventListener(
                    'ic-sgb4-simple-contacts-picker--changed',
                    context.changeWritersListener
                );
            }
        },
        [context]
    );

    const setReadersMountedElement = React.useCallback(
        (mountedElement: HTMLElement) => {
            if (mountedElement) {
                mountedElement.addEventListener(
                    'ic-sgb4-simple-contacts-picker--changed',
                    context.changeReadersListener
                );
            }
        },
        [context]
    );

    return (
        <div>
            <form onSubmit={context.handleSubmit}>
                <h4 className="mb-3 mt-5">
                    <span>General Informations</span>
                </h4>
                <div className="row bg-lvl1 mt-3 pt-3">
                    <div
                        className={
                            context.id === 0 ? 'col-md-12 d-none' : 'col-md-12'
                        }
                    >
                        <div className="form-group">
                            <label>
                                <strong>Owner</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputOwner"
                                disabled={true}
                                value={
                                    context.owners[0] && context.owners[0].email
                                }
                                placeholder="Owner"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>
                                <strong>Short Name</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputShortname"
                                disabled={context.id !== 0}
                                onChange={context.handleInputChange}
                                value={context.shortName}
                                placeholder="Short Name"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>
                                <strong>Description</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputDescription"
                                onChange={context.handleInputChange}
                                placeholder="Description"
                                value={context.description}
                            />
                        </div>
                    </div>
                </div>
                {context.AuditType === 0 ? (
                    <EditCreateAuditGeneralInformation />
                ) : (
                    <EditCreateUserAuditGeneralInformation />
                )}
                <h4 className="mb-3 mt-5">
                    <span>Users Informations</span>
                </h4>
                <div className="row bg-lvl1 pt-3">
                    {context.AuditType === 1 ? (
                        <>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        <strong>Writers</strong>
                                    </label>
                                    <ic-sgb4-simple-contacts-picker
                                        ref={setWritersMountedElement}
                                        selected-ids={
                                            context.writers &&
                                            context.writers.length > 0
                                                ? JSON.stringify(
                                                      context.writers.map(
                                                          p => p.icId
                                                      )
                                                  )
                                                : '[]'
                                        }
                                        display-option="jobtitle"
                                        placeholder="john.doe@sgcib.com"
                                        internal-only="true"
                                        sso-v2="true"
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        <strong>Readers</strong>
                                    </label>
                                    <ic-sgb4-simple-contacts-picker
                                        ref={setReadersMountedElement}
                                        selected-ids={
                                            context.readers &&
                                            context.readers.length > 0
                                                ? JSON.stringify(
                                                      context.readers.map(
                                                          p => p.icId
                                                      )
                                                  )
                                                : '[]'
                                        }
                                        display-option="jobtitle"
                                        placeholder="john.doe@sgcib.com"
                                        sso-v2="true"
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    <strong>Owners</strong>
                                </label>
                                <ic-sgb4-simple-contacts-picker
                                    ref={setWritersMountedElement}
                                    selected-ids={
                                        context.owners &&
                                        context.owners.length > 0
                                            ? JSON.stringify(
                                                  context.owners.map(
                                                      p => p.icId
                                                  )
                                              )
                                            : '[]'
                                    }
                                    display-option="jobtitle"
                                    placeholder="john.doe@sgcib.com"
                                    internal-only="true"
                                    sso-v2="true"
                                />
                            </div>
                        </div>
                    )}
                </div>
                <h4 className="mb-3 mt-5">
                    <span>File</span>
                </h4>
                <div className="row bg-lvl1 pt-3">
                    <div className="col-md-6">
                        <div className="position-relative d-block">
                            <div className="form-group">
                                <input type="file" ref={context.file} />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <button type="submit" className="btn btn-success float-right">
                    {`${context.id === 0 ? 'Create' : 'Update'}${
                        context.AuditType === 0 ? '' : ' User'
                    } Audit`}
                </button>
            </form>
        </div>
    );
};

const EditCreateUserAuditGeneralInformation = () => {
    const context = React.useContext(CreateAuditContext);
    const userContext = React.useContext(AuthenticationContext);
    return (
        <div className="row bg-lvl1">
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>Confidentiality Level</strong>
                    </label>
                    <select
                        className="form-control"
                        id="selectConfidentialityLevel"
                        value={context.confidentialityLevel}
                        onChange={context.handleSelectChange}
                    >
                        <option value="">-</option>
                        <option value="0">C0</option>
                        <option value="1">C1</option>
                    </select>
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>End date</strong>
                    </label>
                    <br />
                    {
                        <DatePicker
                            className={classNames('form-control')}
                            selected={context.endDate}
                            showTimeSelect={true}
                            // maxDate={dateTo}
                            onChange={context.handleDateFromFilterChange}
                            dateFormat="yyyy-MM-dd HH:mm"
                            // calendarIcon={<CalendarTodayIcon className="text-muted" fontSize="inherit" />}
                        />
                    }
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>Available Outside SG Network</strong>
                    </label>
                    <select
                        className="form-control"
                        id="selectAvailableOutsideSgNetwork"
                        value={`${context.availableOutsideSgNetwork || ''}`}
                        onChange={context.handleSelectChange}
                        disabled={
                            !userContext.authenticatedUser ||
                            !userContext.authenticatedUser.canAddExternalReader
                        }
                    >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

const EditCreateAuditGeneralInformation = () => {
    const context = React.useContext(CreateAuditContext);
    return (
        <div className="row bg-lvl1">
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>Access Level</strong>
                    </label>
                    <select
                        className="form-control"
                        id="selectAccessLevel"
                        value={context.accessLevelId}
                        onChange={context.handleSelectChange}
                    >
                        <option value="10">Public</option>
                        <option value="20">Authentificated SGM users</option>
                        <option value="30">
                            Authentificated Internal SGM users
                        </option>
                        <option value="45">User</option>
                        <option value="50">System</option>
                        <option value="60">No Access</option>
                    </select>
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                    <label>
                        <strong>Category</strong>
                    </label>
                    <select
                        className="form-control"
                        id="selectCategory"
                        value={context.categoryId}
                        onChange={context.handleSelectChange}
                    >
                        <option value="1">SG Research Website</option>
                        <option value="2">Analytics Applications</option>
                        <option value="3">GRS Distribution Templates</option>
                        <option value="4">GRS Distribution Webcasts</option>
                        <option value="5">GEF Distribution</option>
                        <option value="6">Indicators Pictures</option>
                        <option value="7">Others Files</option>
                        <option value="8">Toolbox Documents</option>
                        <option value="9">IPad User Audits</option>
                        <option value="10">Execution</option>
                        <option value="12">SG Markets Website</option>
                        <option value="13">Authentication Service</option>
                    </select>
                </div>
            </div>
            <div className="col-md-3 p-4">
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="checkUseCache"
                        defaultChecked={context.isCacheable}
                        onChange={context.handleInputChange}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="checkUseCache"
                    >
                        Cache Audit
                    </label>
                </div>
            </div>
        </div>
    );
};
