import * as React from 'react';
import UnauthorizedError from './UnauthorizedError';
import { ErrorPage } from './Error';

export class NotFoundAudit extends React.PureComponent {
    public render() {
        return (
            <ErrorPage
                code="404"
                title-label="Audit not Found."
                description-label="Sorry, we can't find this Audit."
            />
        );
    }
}

export default UnauthorizedError;
