import * as React from 'react';

type NavigationProps = {
    title: string;
    hideCreateButton?: boolean;
    selectedPills?: string;
};

export const Navigation = (props: NavigationProps) => {

    return (
        <div className="pt-5 border-bottom">
            <div className="container">
                <div className="row d-flex">
                    <div className="col-lg-8 mb-3 mb-lg-0">
                        <h1 className="display-3 m-0">{props.title}</h1>
                    </div>

                    <div className="col-lg-4 d-lg-flex align-items-center justify-content-end">
                        <a
                            href="/create"
                            className="btn btn-primary btn-lg btn-icon-text mr-3 mr-lg-0"
                        >
                            <i className="icon">add</i> Create Website
                        </a>
                    </div>
                </div>
                <ul
                    className="nav nav-pills text-large mt-3 row"
                    role="tablist"
                >
                    <li className="nav-item">
                        <a
                            className={`nav-link pt-3 ${
                                props.selectedPills === 'Audits'
                                    ? 'active show'
                                    : ''
                            }`}
                            href="/"
                        >
                            Audits{' '}
                        </a>
                    </li>
                    {!props.selectedPills ? (
                        <li className="nav-item">
                            <a className="nav-link active show pt-3" href="#">
                                {props.title}
                            </a>
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    );
};
