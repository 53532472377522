import { AuthenticatedUser } from '../models/authenticatedUser';
import { Audit } from '../models/auditModels';

export const hasAdministratorPrivilege = (
    connectedUser: AuthenticatedUser | null
) => {
    return connectedUser && connectedUser.privileges === 'Administrator';
};

export const canCreateUserRessourcePrivilege = (
    connectedUser: AuthenticatedUser | null
) => {
    return connectedUser && connectedUser.privileges !== 'No privileges';
};

export const canEditResource = (
    connectedUser: AuthenticatedUser | null,
    metadata: Audit
) => {
    return (
        hasAdministratorPrivilege(connectedUser)
    );
};

export const getConnectedUserFromBus = (callback: any) => {
    (window as any).SGWTWidgetConfiguration.bus.subscribe(
        'sg-connect.user-info',
        (result: any) => {
            callback(result.origin_network);
        }
    );
};
