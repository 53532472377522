import * as React from 'react';
import { Loading } from '../../loading/Loading';
import { fetcher } from '../../../core/http/fetcher';
import { getConfiguration } from '../../../core/configuration/configurationLoader';
import { AuditMetadata } from '../../../core/models/auditModels';
import { CreateAuditPageProvider } from '../../contexts/CreateAuditPageContext';
import { AuditPageLayout } from '../shared/AuditPageLayout';
import UnauthorizedError, { NotFoundAudit } from '../error/NotFoundAudit';
import {
    AuthenticationConsumer,
} from '../../contexts/AuthenticationContext';
import { Navigation } from '../../common/navigation/Navigation';

export const AuditPage = ({ siteName }: { siteName: string }) => {
    const [metadata, setMetadata] = React.useState<AuditMetadata[] | null>(
        null
    );
    const [isLoading, setIsLoading] = React.useState(true);
    const [isForbidden] = React.useState(false);
    const config = getConfiguration();

    React.useEffect(() => {
        const loadMetadata = async () => {
            try {
                const fileMetadata = await fetcher<AuditMetadata[]>(
                    `${config.apiAuditUrl}/v1/pages/${siteName}`,
                    'GET'
                );
                setMetadata(fileMetadata);
                
            } finally {
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        loadMetadata();
    }, [name, config]);

    if (isLoading) {
        return <Loading />;
    }
    if (isForbidden) {
        return <UnauthorizedError />;
    }
    if (metadata) {
        return (
            <AuthenticationConsumer>
                {({ authenticatedUser }) =>
                    (authenticatedUser && (
                        <>
                            <Navigation
                                title={`Audit RGAA - ${siteName}`}
                                hideCreateButton={true}
                            />
                            <CreateAuditPageProvider metadata={metadata}>
                                <AuditPageLayout />
                            </CreateAuditPageProvider>
                        </>
                    )) || <></>
                }
            </AuthenticationConsumer>
        );
    }
    return <NotFoundAudit />;
};
