import * as React from 'react';
import { getConfiguration } from '../../../core/configuration/configurationLoader';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-account-center': any;
        }
    }
}

export const AccountCenter: React.FC = () => {
    const { environment } = getConfiguration();
    return (
        <sgwt-account-center
            id="sgwtAccountCenter"
            language="en"
            available-languages="en"
            authentication="sg-connect-v2"
            mode="sg-markets"
            environment={environment}
        />
    );
};
