import React from 'react';
import _ from 'lodash';
import {
    Person,
    PersonIc,
    AuditMetadata,
} from '../../core/models/auditModels';
import { sendForm, HttpError } from '../../core/http/fetcher';
import { getConfiguration } from '../../core/configuration/configurationLoader';
import { logTechnical } from '../../core/logging/logger';

const initialState = {
    // tslint:disable-next-line: no-empty
    updateAuditType: (value: number) => {},
    // tslint:disable-next-line: no-empty
    handleInputChange: (elt: React.FormEvent<HTMLInputElement>) => {},
    // tslint:disable-next-line: no-empty
    handleSelectChange: (elt: React.FormEvent<HTMLSelectElement>) => {},
    // tslint:disable-next-line: no-empty
    changeWritersListener: (event: any) => {},
    // tslint:disable-next-line: no-empty
    changeReadersListener: (event: any) => {},
    // tslint:disable-next-line: no-empty
    handleDateFromFilterChange: (event: any) => {},
    // tslint:disable-next-line: no-empty
    handleSubmit: (event: any): boolean => false,
    createAuditSuccess: 0,
    dataCreateError: '',
    AuditType: 1,
    id: 0,
    shortName: '',
    description: '',
    owners: [] as Person[],
    writers: [] as Person[],
    readers: [] as Person[],
    endDate: new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth(),
        new Date().getDay(),
        new Date().getHours(),
        new Date().getMinutes()
    ) as Date | null,
    updateDate: null as Date | null,
    confidentialityLevel: '',
    availableOutsideSgNetwork: 'false',
    accessLevelId: 10,
    categoryId: 1,
    isCacheable: false,
    file: React.createRef<any>(),
};

type CreateAuditProviderState = Readonly<typeof initialState>;

type CreateAuditProviderProps = { metadata?: AuditMetadata };

export type CreateAuditContextState = CreateAuditProviderState;

const context = React.createContext<CreateAuditContextState>(initialState);
const { Provider, Consumer } = context;

const redirectToUserAudit = () => {
    window.location.href = '/';
};

class CreateAuditProvider extends React.Component<
    CreateAuditProviderProps,
    CreateAuditProviderState
> {
    public readonly state: CreateAuditProviderState = initialState;

    constructor(props: CreateAuditProviderProps) {
        super(props);
        this.updateAuditType = this.updateAuditType.bind(this);
        this.handleDateFromFilterChange = this.handleDateFromFilterChange.bind(
            this
        );
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.changeWritersListener = this.changeWritersListener.bind(this);
        this.changeReadersListener = this.changeReadersListener.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public componentDidMount() {
        this.setState({
            updateAuditType: this.updateAuditType,
            handleDateFromFilterChange: this.handleDateFromFilterChange,
            handleInputChange: this.handleInputChange,
            handleSelectChange: this.handleSelectChange,
            changeWritersListener: this.changeWritersListener,
            changeReadersListener: this.changeReadersListener,
            handleSubmit: this.handleSubmit,
        });
        if (this.props.metadata) {
            const metadata = this.props.metadata;
            this.setState({
                id: metadata.id,
                // name: metadata.name,
                // siteName: metadata.siteName,
                // url: !!metadata.url
            });
        }
    }

    public render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }

    private updateAuditType(value: number) {
        this.setState({ AuditType: value });
    }

    private handleDateFromFilterChange = (date: Date) => {
        this.setState({
            endDate: date,
        });
    };

    private handleInputChange = (elt: React.FormEvent<HTMLInputElement>) => {
        if (elt.currentTarget.id === 'inputShortname') {
            this.setState({
                shortName: elt.currentTarget.value,
            });
        } else if (elt.currentTarget.id === 'inputDescription') {
            this.setState({
                description: elt.currentTarget.value,
            });
        } else if (elt.currentTarget.id === 'checkUseCache') {
            this.setState({
                isCacheable: elt.currentTarget.checked,
            });
        }
    };

    private handleSelectChange = (elt: React.FormEvent<HTMLSelectElement>) => {
        if (elt.currentTarget.id === 'selectAccessLevel') {
            this.setState({
                accessLevelId: Number(
                    elt.currentTarget.selectedOptions[0].value
                ),
            });
        } else if (elt.currentTarget.id === 'selectCategory') {
            this.setState({
                categoryId: Number(elt.currentTarget.selectedOptions[0].value),
            });
        } else if (elt.currentTarget.id === 'selectConfidentialityLevel') {
            this.setState({
                confidentialityLevel:
                    elt.currentTarget.selectedOptions[0].value,
            });
        } else if (elt.currentTarget.id === 'selectAvailableOutsideSgNetwork') {
            this.setState({
                availableOutsideSgNetwork:
                    elt.currentTarget.selectedOptions[0].value,
            });
        }
    };

    private changeWritersListener: EventListener = (event: any) => {
        const fieldValue = event.detail.contacts.map(
            (icConctact: PersonIc): Person => {
                return {
                    email: icConctact.email,
                    icId: icConctact.id,
                    personId: 0,
                };
            }
        );

        this.setState(state => {
            return { writers: fieldValue };
        });
    };

    private changeReadersListener: EventListener = (event: any) => {
        const fieldValue = event.detail.contacts.map(
            (icConctact: PersonIc): Person => {
                return {
                    email: icConctact.email,
                    icId: icConctact.id,
                    personId: 0,
                };
            }
        );

        this.setState(state => {
            return { readers: fieldValue };
        });
    };

    private handleSubmit = (event: any) => {
        event.preventDefault();
        if (this.state.AuditType === 0) {
            this.createAudit();
        } else {
            this.createUserAudit();
        }

        return false;
    };

    private createAudit = async () => {
        const formData = new FormData();
        formData.append('Id', `${this.state.id}`);
        formData.append('DataFile', this.state.file.current.files[0]);
        formData.append('ShortName', this.state.shortName);
        formData.append('Description', this.state.description);
        if (this.state.writers && this.state.writers.length > 0) {
            formData.append(
                'Owners',
                this.state.writers && this.state.writers.length > 0
                    ? this.state.writers.map(p => p.icId).join(',')
                    : ''
            );
        }
        formData.append('CategoryId', `${this.state.categoryId}`);
        formData.append('AccessLevelId', `${this.state.accessLevelId}`);
        formData.append('IsCacheable', `${this.state.isCacheable}`);

        const { apiContentUrl } = getConfiguration();
        try {
            await sendForm<any>(
                `${apiContentUrl}/v1/FrontEnd/AddAudit`,
                'POST',
                JSON.stringify(formData)
            );
            this.setState(
                { dataCreateError: '', createAuditSuccess: 1 },
                redirectToUserAudit
            );
        } catch (error) {
            if (error as HttpError) {
                const httpError = error as HttpError;
                this.setState({
                    dataCreateError: transformHttpErrorToErrorMessage(
                        httpError
                    ),
                    createAuditSuccess: 2,
                });
                logTechnical('error', httpError.message, {
                    stack: httpError.stack || '',
                });
            }
        }
    };

    private createUserAudit = async () => {
        const formData = new FormData();
        formData.append('Id', `${this.state.id}`);
        formData.append('DataFile', this.state.file.current.files[0]);
        formData.append('ShortName', this.state.shortName);
        formData.append('Description', this.state.description);
        if (this.state.endDate) {
            formData.append('EndDate', this.state.endDate.toISOString());
        }
        formData.append(
            'AvailableOutsideSgNetwork',
            `${this.state.availableOutsideSgNetwork}`
        );
        if (this.state.writers && this.state.writers.length > 0) {
            formData.append(
                'Writers',
                this.state.writers && this.state.writers.length > 0
                    ? this.state.writers.map(p => p.icId).join(',')
                    : ''
            );
        }
        if (this.state.readers && this.state.readers.length > 0) {
            formData.append(
                'Readers',
                this.state.readers && this.state.readers.length > 0
                    ? this.state.readers.map(p => p.icId).join(',')
                    : ''
            );
        }
        if (this.state.confidentialityLevel) {
            formData.append(
                'ConfidentialityLevel',
                this.state.confidentialityLevel
            );
        }

        const { apiContentUrl } = getConfiguration();
        try {
            await sendForm<any>(
                `${apiContentUrl}/v1/FrontEnd/AddUserAudit`,
                'POST',
                JSON.stringify(formData)
            );
            this.setState(
                { dataCreateError: '', createAuditSuccess: 1 },
                redirectToUserAudit
            );
        } catch (error) {
            if (error as HttpError) {
                const httpError = error as HttpError;
                this.setState({
                    dataCreateError: transformHttpErrorToErrorMessage(
                        httpError
                    ),
                    createAuditSuccess: 2,
                });
                logTechnical('error', httpError.message, {
                    stack: httpError.stack || '',
                });
            }
        }
    };
}
const transformHttpErrorToErrorMessage = (httpError: HttpError): string => {
    const defaultMessage = 'Please fill all fields';
    if (httpError.statusCode === 400) {
        return (
            (httpError.content.errors
                ? transformErrorMessageToString(httpError.content.errors)
                : httpError.content.message) || defaultMessage
        );
    }
    return httpError.content.message || defaultMessage;
};

const transformErrorMessageToString = (content: {
    [name: string]: string;
}): string => {
    const result = [];
    for (const [key, value] of Object.entries(content)) {
        result.push(`${key}: ${value}`);
    }
    return result.join(', ');
};

export {
    CreateAuditProvider,
    Consumer as CreateAuditConsumer,
    context as CreateAuditContext,
};
