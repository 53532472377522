import configDev from '../../config.dev.json';
import configLocal from '../../config.local.json';
import configPrd from '../../config.prd.json';
import configUat from '../../config.uat.json';
import { Configuration } from './configuration';

let config: Configuration;

export const loadConfiguration = (hostname: string): void => {
    if (hostname === 'local.fr.world.socgen') {
        config = configLocal;
        return;
    }

    if (hostname === 'sgmarkets-web-rgaa-dev.sgmarkets.com') {
        config = configDev;
        return;
    }

    if (hostname === 'sgmarkets-web-rgaa-uat.sgmarkets.com') {
        config = configUat;
        return;
    }

    if (
        hostname === 'sgmarkets-web-rgaa.sgmarkets.com' ||
        hostname === 'sgmarkets-web-rgaa-preview.sgmarkets.com'
    ) {
        config = configPrd;
        return;
    }

    throw new Error(`Could not load configuration for: ${hostname}`);
};

export const getConfiguration = () => {
    return config;
};
