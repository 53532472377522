import React from 'react';
import { getConfiguration } from '../../core/configuration/configurationLoader';
import { fetcher } from '../../core/http/fetcher';
import { logTechnical } from '../../core/logging/logger';
import _ from 'lodash';
import { Audit, Filters } from '../../core/models/auditModels';

type FiltersGroup = { [key: string]: Filters[] };

const defaultSelectedFilters = (): { [key: string]: string[] } => {
    return {
        'Access Level': [],
        Categories: [],
        'Mime-Types': [],
        Owners: [],
    };
};

const initialState = {
    count: 0,
    isLoading: true,
    // tslint:disable-next-line: no-empty
    updateSearch: (filename: string) => {},
    // tslint:disable-next-line: no-empty
    updatePager: (skip: number) => {},
    // tslint:disable-next-line: no-empty
    updateFilter: () => {},
    hasAvailableFilters: () => false,
    // tslint:disable-next-line: no-empty
    onChangeSelectedFilter: (filterGroup: string, id: string): any => {},
    skip: 0,
    take: 20,
    search: '',
    Audits: [] as Audit[],
    availableFilters: {} as FiltersGroup,
    selectedFilters: defaultSelectedFilters(),
};

type SearchAuditsProviderState = Readonly<typeof initialState>;

export type SearchAuditsContextState = SearchAuditsProviderState;

const context = React.createContext<SearchAuditsContextState>(initialState);
const { Provider, Consumer } = context;

class SearchAuditsProvider extends React.Component<
    {},
    SearchAuditsProviderState
> {
    public readonly state: SearchAuditsProviderState = initialState;
    private searchDebounceTimeout: NodeJS.Timeout | undefined;

    constructor(props: any) {
        super(props);
        this.updateSearch = this.updateSearch.bind(this);
        this.search = this.search.bind(this);
        this.searchAndUpdateFilter = this.searchAndUpdateFilter.bind(this);
        this.updateSearchDebounce = this.updateSearchDebounce.bind(this);
        this.updatePager = this.updatePager.bind(this);
        this.onChangeSelectedFilter = this.onChangeSelectedFilter.bind(this);
        this.hasAvailableFilters = this.hasAvailableFilters.bind(this);
    }

    public componentDidMount() {
        this.setState(
            {
                updateSearch: this.updateSearch,
                updatePager: this.updatePager,
                onChangeSelectedFilter: this.onChangeSelectedFilter,
                hasAvailableFilters: this.hasAvailableFilters,
            },
            () => {
                this.searchAndUpdateFilter();
            }
        );
    }

    public render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }

    private hasAvailableFilters(): boolean {
        return _.some(this.state.availableFilters, k => k.length > 0);
    }

    private updatePager(skip: number) {
        this.setState({ skip }, this.forceUpdateSearchDebounce);
    }

    private forceUpdateSearchDebounce() {
        if (this.searchDebounceTimeout) {
            clearTimeout(this.searchDebounceTimeout);
        }
        this.searchDebounceTimeout = setTimeout(() => {
            this.search();
        }, 0);
    }

    private updateSearchDebounce() {
        if (this.searchDebounceTimeout) {
            clearTimeout(this.searchDebounceTimeout);
        }
        this.searchDebounceTimeout = setTimeout(() => {
            this.setState({ skip: 0 }, this.searchAndUpdateFilter);
        }, 1000);
    }

    private updateSearch(filename: string) {
        this.setState({ search: filename }, this.updateSearchDebounce);
    }

    private async searchCount() {
        const { apiContentUrl } = getConfiguration();
        try {
            const filter = transformFilterGroupToQuery(
                this.state.selectedFilters
            );
            const userInformation = await fetcher<number>(
                `${apiContentUrl}/v1/FrontEnd/Search/Audits/Count?partialName=${
                    this.state.search
                }${filter ? `&${filter}` : ''}`,
                'GET'
            );

            this.setState(updateSearchCount(userInformation));
        } catch (error) {
            logTechnical('error', error.message, { stack: error.stack || '' });
            this.setState({ count: 0 });
        }
    }

    private async searchAudits() {
        const { apiAuditUrl } = getConfiguration();
        try {
            const Audit = await fetcher<Audit[]>(
                `${apiAuditUrl}/v1/sites?pageNumber=${
                    this.state.skip
                }&pageSize=${this.state.take}&partialName=${this.state.search}`,
                'GET'
            );

            this.setState(updateSearchAudit(Audit));
        } catch (error) {
            logTechnical('error', error.message, { stack: error.stack || '' });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    private async searchFilters() {
        const { apiContentUrl } = getConfiguration();
        try {
            const accessLevelFilters = fetcher<Filters[]>(
                `${apiContentUrl}/v1/FrontEnd/Search/AccessLevels?partialName=${this.state.search}`,
                'GET'
            );

            const categoriesFilters = fetcher<Filters[]>(
                `${apiContentUrl}/v1/FrontEnd/Search/Categories?partialName=${this.state.search}`,
                'GET'
            );

            const mimetypeFilters = fetcher<Filters[]>(
                `${apiContentUrl}/v1/FrontEnd/Search/MimeTypes?partialName=${this.state.search}`,
                'GET'
            );
            const ownerFilters = fetcher<Filters[]>(
                `${apiContentUrl}/v1/FrontEnd/Search/Owners?partialName=${this.state.search}`,
                'GET'
            );

            this.setState({
                availableFilters: {
                    'Access Level': await accessLevelFilters,
                    Categories: await categoriesFilters,
                    'Mime-Types': await mimetypeFilters,
                    Owners: await ownerFilters,
                },
            });
        } catch (error) {
            logTechnical('error', error.message, { stack: error.stack || '' });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    private onChangeSelectedFilter(filterGroup: string, id: string) {
        return (event: React.SyntheticEvent<HTMLInputElement>) => {
            const selectedFilter = _.clone(
                this.state.selectedFilters[filterGroup] || []
            );
            const checked = event.currentTarget.checked;
            if (checked) {
                selectedFilter.push(id);
            } else {
                _.remove(selectedFilter, n => n === id);
            }
            this.setState(
                updateSelectedFilters(filterGroup, _.uniq(selectedFilter)),
                this.forceUpdateSearchDebounce
            );
        };
    }

    private searchAndUpdateFilter() {
        this.searchFilters();
        this.search();
    }

    private search() {
        this.searchCount();
        this.searchAudits();
    }
}

const transformFilterGroupToQuery = (group: { [key: string]: string[] }) => {
    const transformContent = [
        { groupName: 'Access Level', queryParams: 'accessLevelIds' },
        { groupName: 'Categories', queryParams: 'categoryIds' },
        { groupName: 'Mime-Types', queryParams: 'mimeTypes' },
        { groupName: 'Owners', queryParams: 'ownerIds' },
    ];
    const resultArray = [] as string[];
    for (const content of transformContent) {
        const transform = transformNumberArrayToArrayQuery(
            group[content.groupName]
        );
        if (transform) {
            resultArray.push(`${content.queryParams}=${transform}`);
        }
    }
    return resultArray.join('&');
};

const transformNumberArrayToArrayQuery = (array: string[]) => {
    return array.join(',') || '';
};

const updateSearchCount = (count: number) => (
    prevState: SearchAuditsContextState
): SearchAuditsContextState => ({
    ...prevState,
    count,
});

const updateSearchAudit = (Audits: Audit[]) => (
    prevState: SearchAuditsContextState
): SearchAuditsContextState => ({
    ...prevState,
    Audits,
});

const updateSelectedFilters = (name: string, value: string[]) => (
    prevState: SearchAuditsContextState
): SearchAuditsContextState => ({
    ...prevState,
    selectedFilters: { ...prevState.selectedFilters, [name]: value },
});

export {
    SearchAuditsProvider,
    Consumer as SearchAuditsConsumer,
    context as SearchAuditsContext,
};
