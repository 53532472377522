import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-core';
import { SGConnectConfiguration } from './configuration/configuration';

let sgwtConnect: SGWTConnectCore;

export const loadSGConnect = (configuration: SGConnectConfiguration) => {
    if (!sgwtConnect) {
        sgwtConnect = setupSGWTConnect({
            authorization_endpoint: configuration.authorizationEndpoint,
            client_id: configuration.clientId,
            scope: configuration.scope,
            acr_values: configuration.level,
        });
    }

    return sgwtConnect;
};

export const setupPassiveMode = () => {
    const widget = document.querySelector('sgwt-connect') as any;
    if (widget) {
        if (typeof widget.setSgwtConnectInstance !== 'undefined') {
            widget.setSgwtConnectInstance(sgwtConnect);
        } else {
            setTimeout(setupPassiveMode, 100);
        }
    }
};
export const getSGConnect = () => {
    return sgwtConnect;
};
