import React from 'react';
import { AccountCenter } from '../accountCenter/AccountCenter';

export const Header: React.FC = () => {
    return (
        <header>
            <div className="pb-2">
                <div className="container pl-0">
                    <nav className="navbar navbar-expand-xl navbar-light bg-white">
                        <div className="d-flex mr-xl-auto">
                            <a
                                data-v-0643ab49=""
                                href="/"
                                className="navbar-brand d-flex align-items-center router-link-active"
                            >
                                <svg
                                    data-v-0643ab49=""
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="32"
                                    width="32"
                                >
                                    <path
                                        fill="#ed1a3b"
                                        d="M0 0h24v12.246H0z"
                                    />
                                    <path fill="#231f20" d="M0 12h24v12H0z" />
                                    <path
                                        fill="#fff"
                                        d="M4.19 11.272h15.611v1.457H4.19z"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div className="d-flex align-items-center order-xl-1">
                            <AccountCenter />
                            <svg
                                className="d-none d-md-block overflow-visible ml-3"
                                width="145"
                                height="32"
                            >
                                <image
                                    href="/sg_market.svg"
                                    x="0"
                                    y="0"
                                    width="145"
                                    height="16"
                                />
                                <text
                                    className="font-family-display"
                                    x="0"
                                    y="32"
                                    width="145"
                                    height="16"
                                >
                                    RGAA Audit
                                </text>
                            </svg>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};
