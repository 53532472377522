import { getConfiguration } from '../configuration/configurationLoader';
import { getSGConnect } from '../sgConnect';

type BasicType = string | number | Date | boolean | object;

interface FunctionalLog {
    feature: string;
    message: string;
    parameters?: { [key: string]: {} };
}

interface TechnicalLog {
    type: 'info' | 'warn' | 'error';
    message: string;
    parameters?: { [key: string]: BasicType };
}

interface PerformanceLog {
    feature: string;
    elapsedTime: number;
    message: string;
    parameters?: { [key: string]: {} };
}

const functionalLogs: FunctionalLog[] = [];
export const logFunctional = async (
    feature: string,
    message: string,
    parameters?: { [key: string]: {} }
) => {
    functionalLogs.push({
        feature,
        message,
        parameters,
    });

    const isSucess = await sendLogs('functional', functionalLogs);
    if (isSucess) {
        functionalLogs.splice(0, functionalLogs.length);
    }
};

const technicalLogs: TechnicalLog[] = [];
export const logTechnical = async (
    type: 'info' | 'warn' | 'error',
    message: string,
    parameters?: { [key: string]: {} }
) => {
    technicalLogs.push({
        type,
        message,
        parameters,
    });

    const isSuccess = await sendLogs('technical', technicalLogs);
    if (isSuccess) {
        technicalLogs.splice(0, technicalLogs.length);
    }
};

const performanceLogs: PerformanceLog[] = [];
const logPerformance = async (performanceLog: PerformanceLog) => {
    performanceLogs.push(performanceLog);

    const isSuccess = await sendLogs('performance', performanceLogs);
    if (isSuccess) {
        performanceLogs.splice(0, performanceLogs.length);
    }
};

export class PerformanceMeasureLogger {
    public static start(
        feature: string,
        message: string,
        parameters?: { [key: string]: {} }
    ): PerformanceMeasureLogger {
        return new PerformanceMeasureLogger(feature, message, parameters);
    }

    private begin: number;
    private constructor(
        private feature: string,
        private message: string,
        private parameters?: { [key: string]: {} }
    ) {
        this.begin = performance.now();
    }

    public stop(): void {
        const elapsedTime = performance.now() - this.begin;
        logPerformance({
            feature: this.feature,
            message: this.message,
            elapsedTime,
            parameters: this.parameters,
        });
    }
}

async function sendLogs(
    type: 'technical' | 'functional' | 'performance',
    logs: FunctionalLog[] | TechnicalLog[] | PerformanceLog[]
): Promise<boolean> {
    const jsonBody = JSON.stringify(logs);

    const headers: string[][] = [
        ['accept', 'application/json'],
        ['content-type', 'application/json'],
    ];

    const sgConnect = getSGConnect();
    const token = sgConnect && sgConnect.getAuthorizationHeader();
    if (token) {
        headers.push(['authorization', token]);
    }

    const { apiContentUrl } = getConfiguration();
    const url = `${apiContentUrl}/v1/logs/${type}`;

    console.log(jsonBody);
    console.error(
        `Cannot send errors to the server after 3 times - url: ${url}`
    );
    return false;
}
