import * as React from 'react';
import { CreateAuditPageContext } from '../../contexts/CreateAuditPageContext';
import { EditAuditForm } from './EditAuditForm';
import UnauthorizedError from '../error/NotFoundAudit';
import { Loading } from '../../loading/Loading';

export const AuditPageLayout = () => {
    const context = React.useContext(CreateAuditPageContext);

    return context.id !== 0 ? (
        <div className="pt-5">
            <div className="container">
                {context.createAuditPageSuccess === 2 ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        Audit has not been saved.
                        {context.dataCreateError}
                    </div>
                ) : context.createAuditPageSuccess === 1 ? (
                    <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                    >
                        Audit has been saved.
                    </div>
                ) : context.createAuditPageSuccess === 4 ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        Audit has not been computed.
                    </div>
                ) : context.createAuditPageSuccess === 3 ? (
                    <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                    >
                        Audit has been computed.
                    </div>
                ) : context.createAuditPageSuccess === 5 ? (
                    <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                    >
                        Page has been created.
                    </div>
                ) : context.createAuditPageSuccess === 6 ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        Page has not been created.
                    </div>
                ) : context.createAuditPageSuccess === 7 ? (
                    <div
                        className="alert alert-warning alert-dismissible fade show"
                        role="alert"
                    >
                        Page name or Page url is missing.
                    </div>
                ) : context.createAuditPageSuccess === 8 ? (
                    <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                    >
                        Page deleted.
                    </div>
                ) : context.createAuditPageSuccess === 9 ? (
                    <div
                        className="alert alert-warning alert-dismissible fade show"
                        role="alert"
                    >
                        Page cannot be deleted.
                    </div>
                ) : context.createAuditPageSuccess === 10 ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        Cannot delete last page of the website.
                    </div>
                ) : (
                    <span />
                )}
                <h4 className="mb-3">
                    <span> Audit Page: {context.selectedPageName}</span>
                </h4>
                {context.pages.length > 0 ? (
                <>
                <div className="row bg-lvl1 pt-3">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>
                                <strong>
                                    Please choose the page to Audit
                                </strong>
                            </label>
                            <div className="form-inline">
                                <select
                                    className="form-control w-50"
                                    id="selectPagre"
                                    value={context.selectedPageName}
                                    onChange={context.handleSelectChange}
                                >
                                    {context.pages.map((page, index) => (
                                        <option
                                            value={page.name}
                                        >
                                            {page.name}
                                        </option>
                                    ))}
                                </select>
                                <button type="button" value="Compute" onClick={() => (context.collapseAddPage())} className="btn btn-success float-right">Add Page</button>
                                <button type="button" value="Compute" onClick={() => (context.handleDeletePageSubmit())} className="btn btn-warning float-right">Delete Page</button>
                                
                                {context.pages.map((page, index) => {
                                    return page.name === context.selectedPageName ?
                                    <div className='w-100'>Url : <a target='_blank' href={ page.url }>{ page.url }</a></div>:''
                                    })}
                                    
                            </div>
                        </div>
                    </div>
                </div>
                {context.addPageVisible ? (
                        
                            <div className="bg-lvl1 p-3 mb-5 ml-5 mr-5" style={{border: "1px solid rgba(196,200,202,.7)"}}>
                                {!context.isAddPageLoading ? (
                                <form onSubmit={context.handleAddPageSubmit}>

                                    <div className="form-group">
                                        <label >Name</label>
                                        <input type="text" className="form-control" id="inputPageName" aria-describedby="emailHelp" placeholder="Enter name"
                                            onChange={context.handleInputChange}
                                            value={context.inputPageName} />
                                    </div>
                                    <div className="form-group">
                                        <label >Url</label>
                                        <input type="text" className="form-control" id="inputPageUrl" aria-describedby="emailHelp" placeholder="Enter url"
                                            onChange={context.handleInputChange}
                                            value={context.inputPageUrl} />
                                    </div>
                                    <div className="row">
                                        <div className="col-10">
                                        </div>
                                        <div className="col-2">
                                            <button type="submit" onClick={() => (context.submitAction(2))} value="Save" className="btn btn-success float-right">Add Page</button>
                                        </div>
                                    </div>
                                </form>
                                ) : (<Loading />)}
                            </div>
                            ) : (
                                <span></span>
                            )
                }
                </>
                ) :  (
                    <span />
                )}
                <EditAuditForm />
            </div>
        </div>
    ) : (
        <UnauthorizedError />
    );
};
