import React from 'react';
import { NotificationToast } from '.';

export const NotificationFailedToast: React.FC<NotificationToast> = props => {
    const { count, closeToast, itemMessage } = props;
    const context = props.context === 'add' ? 'add' : 'close';
    const itemsString = props.count > 1 ? 'items' : 'item';
    const itemMsg = itemMessage ? itemMessage : `${count} ${itemsString}`;
    return (
        <div>
            <span className="badge badge-danger badge-prepend-square text-wrap">
                {`${itemMsg} ${context} failed`}
            </span>
            <button
                type="button"
                className="mt-2 close"
                data-dismiss="toast"
                aria-label="Close"
                onClick={closeToast}
            >
                <i className="material-icons">close</i>
            </button>
        </div>
    );
};
