import * as React from 'react';
import classNames from 'classnames';
import { Audit } from '../../../../core/models/auditModels';

export const AuditCard = ({ Audit }: { Audit: Audit }) => {

    return (
        <div className="product-card col-12 col-xl-12 d-flex">
            <div
                style={{ borderTop: `3px solid #666` }}
                className={classNames(
                    'card border-left border-right border-bottom card-raising mb-4 w-100'
                )}
            >
                <div className="card-header pb-0">
                    <span
                        className="text-uppercase text-large"
                    >
                    </span>
                    <div className="card-title">
                        <a
                            href={`/audit/${Audit.name}`}
                        >
                        <span className="text-xxlarge">
                            {Audit.name}{' '}
                        </span>
                        </a>
                    </div>
                </div>
                <div className='card-body'>
                    <p className="mb-0">
                        <span className="text-secondary">Url</span>:{' '}
                        <a
                            target="blank"
                            href={`${Audit.url}`}                        >
                            <span className="text-primary">
                                {Audit.url}{' '}
                                <i className="icon icon-md">open_in_new</i>
                            </span>
                        </a>
                    </p>
                </div>
                    <div className="card-footer text-right p-0">
                        <a
                            className={`btn ${
                                Audit.isDeleted
                                    ? 'btn-warning'
                                    : 'btn-info'
                            } btn-lg btn-icon-text`}
                            href={`/edit/${Audit.name}`}
                        >
                            <i className="icon">edit</i>Edit
                        </a>
                        <a
                            className={`btn ${
                                Audit.isDeleted
                                    ? 'btn-warning'
                                    : 'btn-success'
                            } btn-lg btn-icon-text`}
                            target="_blank"
                            href={`https://localhost:5001/api/v1/rgaa/report/${Audit.name}`}
                        >
                            <i className="icon">save</i>Export
                        </a>
                    </div>
            </div>
        </div>
    );
};
