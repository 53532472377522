import React, { lazy, Suspense } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import styles from './App.module.css';
import {
    AuthenticationConsumer,
    AuthenticationProvider,
} from './components/contexts/AuthenticationContext';
import { NotificationsProvider } from './components/contexts/NotificationsContext';
import { withErrorBoundary } from './components/errorBoundary/ErrorBoundary';
import { ErrorMessage } from './components/errorBoundary/ErrorMessage';
import { Footer } from './components/layout/footer/Footer';
import { Header } from './components/layout/header/Header';
import { HelpCenter } from './components/layout/helpCenter/HelpCenter';
import { Loading } from './components/loading/Loading';
import { CreatePage } from './components/pages/createPage/CreatePage';
import { EditPage } from './components/pages/editPage/EditPage';
import { AuditPage } from './components/pages/auditPage/AuditPage';

import UnauthorizedError from './components/pages/error/UnauthorizedError';
import { SearchPage } from './components/pages/searchResourcePage/SearchPage';

const NotFound = lazy(() => import('./components/pages/error/NotFound'));

const MessageErrorBoundary = withErrorBoundary(ErrorMessage);

const Routes: React.FC = () => (
    <Switch>
        <Route path="/" exact={true} component={() => <SearchPage />} />
        <Route
            path="/audits"
            exact={true}
            component={() => <Redirect to={'/'} />}
        />
        <Route
            exact={true}
            path="/edit/:id"
            component={(props: any) => (
                <EditPage shortName={props.match.params.id} />
            )}
        />
        <Route
            exact={true}
            path="/audit/:id"
            component={(props: any) => (
                <AuditPage siteName={props.match.params.id} />
            )}
        />
        <Route path="/create" exact={true} component={() => <CreatePage />} />
        <Route path="/error403" component={() => <UnauthorizedError />} />
        <Route path="*" component={() => <NotFound />} />
    </Switch>
);

const Layout: React.FC = () => {
    return (
        <NotificationsProvider>
            <Header />
            <div className={styles.main}>
                <div className="container mb-5">
                    <Suspense fallback={<Loading />}>
                        <PageLayoutLoader />
                    </Suspense>
                </div>
            </div>
            <Footer />
            <HelpCenter />
        </NotificationsProvider>
    );
};

const PageLayoutLoader: React.FC = () => (
    <AuthenticationConsumer>
        {({ isLoading }) => (isLoading && <Loading />) || <PageLayout />}
    </AuthenticationConsumer>
);

export const PageLayout: React.FC = () => (
    <AuthenticationConsumer>
        {({ authenticatedUser }) =>
            (authenticatedUser && authenticatedUser.isConnectedFromLan && (
                <>
                    <Routes />
                </>
            )) || <UnauthorizedError />
        }
    </AuthenticationConsumer>
);

const App: React.FC = () => (
    <MessageErrorBoundary>
        <Router>
            <AuthenticationProvider>
                <AuthenticationConsumer>
                    {authenticationContext =>
                        authenticationContext.authenticatedUser && <Layout />
                    }
                </AuthenticationConsumer>
            </AuthenticationProvider>
        </Router>
    </MessageErrorBoundary>
);

export default App;
