import * as React from 'react';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import { Navigation } from '../../common/navigation/Navigation';
import { SearchAuditsProvider } from '../../contexts/SearchAuditsContext';
import { SearchLayout } from './components/SearchLayout';
import { Loading } from '../../loading/Loading';

export const SearchPage = () => {
    const userContext = React.useContext(AuthenticationContext);

    if (userContext.authenticatedUser) {
        return (
            <SearchAuditsProvider>
                <Navigation title="Audits RGAA" selectedPills="Audits" />
                <SearchLayout />
            </SearchAuditsProvider>
        );
    }
    return <Loading />;
};
