import React from 'react';
import style from './Footer.module.css';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-mini-footer': any;
        }
    }
}

export const Footer: React.FC = () => {
    return (
        <div className={`w-100 ${style.miniFooter}`}>
            <sgwt-mini-footer
                id="sgwt-mini-footer"
                mode="sg-markets"
                contact-us="{'mail':'SGMarkets@sgcib.com'}"
                design="light"
            />
        </div>
    );
};
