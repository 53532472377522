import * as React from 'react';
import { CreateAuditContext } from '../../contexts/CreateAuditContext';
import { EditCreateAuditForm } from './EditCreateAuditForm';
import { AuthenticationContext } from '../../contexts/AuthenticationContext';
import {
    canCreateUserRessourcePrivilege,
    hasAdministratorPrivilege,
} from '../../../core/service/ConnectedUserPrivilege';
import UnauthorizedError from '../error/NotFoundAudit';

export const EditCreatePageLayout = () => {
    const context = React.useContext(CreateAuditContext);
    const userContext = React.useContext(AuthenticationContext);
    const handleChangeAuditType = React.useCallback(
        (event: any) => {
            context.updateAuditType(Number(event.currentTarget.value));
        },
        [context]
    );
    const canCreate = canCreateUserRessourcePrivilege(
        userContext.authenticatedUser
    );
    return context.id !== 0 || canCreate ? (
        <div className="pt-5">
            <div className="container">
                {context.createAuditSuccess === 2 ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        Audit has not been{' '}
                        {context.id === 0 ? 'created' : 'updated'}:{' '}
                        {context.dataCreateError}
                    </div>
                ) : context.createAuditSuccess === 1 ? (
                    <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                    >
                        Audit has been{' '}
                        {context.id === 0 ? 'created' : 'updated'}.
                    </div>
                ) : (
                    <span />
                )}
                <h4 className="mb-3">
                    <span> Audit Type</span>
                </h4>
                <div className="row bg-lvl1 pt-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>
                                <strong>
                                    Please choose the type of your Audit
                                </strong>
                            </label>
                            <select
                                className="form-control"
                                id="selectConfidentialityLevel"
                                value={context.AuditType}
                                onChange={handleChangeAuditType}
                                disabled={
                                    context.id !== 0 ||
                                    !hasAdministratorPrivilege(
                                        userContext.authenticatedUser
                                    )
                                }
                            >
                                <option
                                    value="0"
                                    selected={context.AuditType === 0}
                                >
                                    Audit
                                </option>
                                <option
                                    value="1"
                                    selected={context.AuditType === 1}
                                >
                                    User Audit
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <EditCreateAuditForm />
            </div>
        </div>
    ) : (
        <UnauthorizedError />
    );
};
