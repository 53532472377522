import * as React from 'react';
import { Loading } from '../../loading/Loading';
import { fetcher } from '../../../core/http/fetcher';
import { getConfiguration } from '../../../core/configuration/configurationLoader';
import { AuditMetadata } from '../../../core/models/auditModels';
import { CreateAuditProvider } from '../../contexts/CreateAuditContext';
import { EditCreatePageLayout } from '../shared/EditCreatePageLayout';
import UnauthorizedError, { NotFoundAudit } from '../error/NotFoundAudit';
import {
    AuthenticationConsumer,
} from '../../contexts/AuthenticationContext';
import { Navigation } from '../../common/navigation/Navigation';

export const EditPage = ({ shortName }: { shortName: string }) => {
    const [metadata, setMetadata] = React.useState<AuditMetadata | null>(
        null
    );
    const [isLoading, setIsLoading] = React.useState(true);
    const [isForbidden] = React.useState(false);
    const config = getConfiguration();

    React.useEffect(() => {
        const loadMetadata = async () => {
            try {
                let AuditPath = 'Audits';
                const fileMetadata = await fetcher<AuditMetadata>(
                    `${config.apiContentUrl}/v1/${AuditPath}/${shortName}/Metadata`,
                    'GET'
                );
                setMetadata(fileMetadata);
            } finally {
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        loadMetadata();
    }, [name, config]);

    if (isLoading) {
        return <Loading />;
    }
    if (isForbidden) {
        return <UnauthorizedError />;
    }
    if (metadata) {
        return (
            <AuthenticationConsumer>
                {({ authenticatedUser }) =>
                    (authenticatedUser && (
                        <>
                            <Navigation
                                title={`Edit Audit RGAA - ${shortName}`}
                                hideCreateButton={true}
                            />
                            <CreateAuditProvider metadata={metadata}>
                                <EditCreatePageLayout />
                            </CreateAuditProvider>
                        </>
                    )) || <></>
                }
            </AuthenticationConsumer>
        );
    }
    return <NotFoundAudit />;
};
