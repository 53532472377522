import * as React from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-help-center': any;
        }
    }
}

export const HelpCenter: React.FC = () => {
    return (
        <aside>
            <sgwt-help-center
                id="sgwtSuperHelpCenter"
                sg-connect-support="sg-connect-v2"
                application-id="content"
                default-send-to="c2dtYXJrZXRzQHNnY2liLmNvbQ=="
            />
        </aside>
    );
};
