import React from 'react';
import { NotificationMessage } from '../../contexts/NotificationsContext';
import { NotificationToast } from '.';

type NotificationErrorToastProps = Pick<NotificationMessage, 'context'> &
    Pick<NotificationMessage, 'message'> &
    Pick<NotificationToast, 'closeToast'>;

export const NotificationErrorToast: React.FC<NotificationErrorToastProps> = props => {
    const context = props.context === 'add' ? 'adding' : 'closing';
    const message =
        props.message || `An error occured when ${context} subscriptions.`;

    return (
        <div>
            <span className="badge badge-danger badge-prepend-square">
                {message}
            </span>

            <button
                type="button"
                className="mt-2 close"
                data-dismiss="toast"
                aria-label="Close"
                onClick={props.closeToast}
            >
                <i className="material-icons">close</i>
            </button>
        </div>
    );
};
