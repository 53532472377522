import * as React from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sg-error-page': any;
        }
    }
}

export const ErrorPage = (props: {
    code: string;
    'title-label'?: string;
    'description-label'?: string;
}) => (
    <sg-error-page
        {...props}
        contact-us="help-center"
        language="en"
        action-button-link="/"
        action-button-label="Go Back"
    />
);
