import * as React from 'react';
import { ErrorPage } from './Error';

export class UnauthorizedError extends React.PureComponent {
    public render() {
        return (
            <ErrorPage
                code="403"
                title-label="Unauthorized"
                description-label="Sorry, you don't have privilege to see this content."
            />
        );
    }
}

export default UnauthorizedError;
