import * as React from 'react';
import { CreateAuditProvider } from '../../contexts/CreateAuditContext';
import { EditCreatePageLayout } from '../shared/EditCreatePageLayout';
import { AuthenticationConsumer } from '../../contexts/AuthenticationContext';
import { Navigation } from '../../common/navigation/Navigation';
import {
    canCreateUserRessourcePrivilege,
    hasAdministratorPrivilege,
} from '../../../core/service/ConnectedUserPrivilege';
import UnauthorizedError from '../error/NotFoundAudit';

export const CreatePage = () => (
    <AuthenticationConsumer>
        {({ authenticatedUser }) =>
            authenticatedUser ? (
                canCreateUserRessourcePrivilege(authenticatedUser) ||
                hasAdministratorPrivilege(authenticatedUser) ? (
                    <>
                        <Navigation
                            title="Create Audit"
                            hideCreateButton={true}
                        />
                        <CreateAuditProvider>
                            <EditCreatePageLayout />
                        </CreateAuditProvider>
                    </>
                ) : (
                    <UnauthorizedError />
                )
            ) : (
                <></>
            )
        }
    </AuthenticationConsumer>
);
