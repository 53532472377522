import React from 'react';
import _ from 'lodash';
import {
    AuditMetadata,
} from '../../core/models/auditModels';
import { getConfiguration } from '../../core/configuration/configurationLoader';
import { sendForm, HttpError } from '../../core/http/fetcher';
import { logTechnical } from '../../core/logging/logger';
import { fetcher } from '../../core/http/fetcher';

const initialState = {
    // tslint:disable-next-line: no-empty
    updateAuditType: (value: number) => {},
    // tslint:disable-next-line: no-empty
    handleInputChange: (elt: React.FormEvent<HTMLInputElement>) => {},
    // tslint:disable-next-line: no-empty
    handleSelectChange: (elt: React.FormEvent<HTMLSelectElement>) => {},
    // tslint:disable-next-line: no-empty
    changeWritersListener: (event: any) => {},
    // tslint:disable-next-line: no-empty
    changeReadersListener: (event: any) => {},
    // tslint:disable-next-line: no-empty
    handleDateFromFilterChange: (event: any) => {},
    // tslint:disable-next-line: no-empty
    handleSubmit: (event: any): boolean => false,
    // tslint:disable-next-line: no-empty
    handleAddPageSubmit: (event: any): boolean => false,
    // tslint:disable-next-line: no-empty
    handleDeletePageSubmit: (): boolean => false,
    // tslint:disable-next-line: no-empty
    updateRule: (page: any, rule: any, elt: any) => {},
    // tslint:disable-next-line: no-empty
    submitAction: (action: number) => {},
    // tslint:disable-next-line: no-empty
    collapseAddPage: () => {},
    
    pages: [] as AuditMetadata[],
    page: undefined as AuditMetadata | undefined,
    id: 1,
    selectedPageName: '',
    createAuditPageSuccess: 0,
    dataCreateError: '',
    isLoading: false,
    isAddPageLoading: false,
    isComputed: false,
    submitedAction: 1,
    addPageVisible: false,
    inputPageName: '',
    inputPageUrl: ''
};

type CreateAuditPageProviderState = Readonly<typeof initialState>;

type CreateAuditPageProviderProps = { metadata?: AuditMetadata[] };

export type CreateAuditPageContextState = CreateAuditPageProviderState;

const context = React.createContext<CreateAuditPageContextState>(initialState);
const { Provider, Consumer } = context;

class CreateAuditPageProvider extends React.Component<
    CreateAuditPageProviderProps,
    CreateAuditPageProviderState
> {
    public readonly state: CreateAuditPageProviderState = initialState;

    constructor(props: CreateAuditPageProviderProps) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateRule = this.updateRule.bind(this);
        this.submitAction = this.submitAction.bind(this);
        this.collapseAddPage = this.collapseAddPage.bind(this);
        this.handleAddPageSubmit = this.handleAddPageSubmit.bind(this);
        this.handleDeletePageSubmit = this.handleDeletePageSubmit.bind(this);
        
    }

    public componentDidMount() {
        this.setState({
            handleInputChange: this.handleInputChange,
            handleSelectChange: this.handleSelectChange,
            handleSubmit: this.handleSubmit,
            updateRule: this.updateRule,
            submitAction: this.submitAction,
            collapseAddPage: this.collapseAddPage,
            handleAddPageSubmit: this.handleAddPageSubmit,
            handleDeletePageSubmit: this.handleDeletePageSubmit
        });
        if (this.props.metadata) {
            const metadata = this.props.metadata;
            this.setState({
                pages: metadata,
                selectedPageName: metadata[0].name,
                page: metadata[0]
            });
        }
    }

    public render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }

    private handleInputChange = (elt: React.FormEvent<HTMLInputElement>) => {
        if (elt.currentTarget.id === 'inputPageName') {
            this.setState({
                inputPageName: elt.currentTarget.value,
            });
        } else if (elt.currentTarget.id === 'inputPageUrl') {
            this.setState({
                inputPageUrl: elt.currentTarget.value,
            });
        }
    };

    private findPagebyName = (array: AuditMetadata[], name: string): AuditMetadata | undefined => {
        return array.find((element: AuditMetadata) => {
          return element.name === name;
        })
    }

    private handleSelectChange = (elt: React.FormEvent<HTMLSelectElement>) => {
        this.setState({ 
            selectedPageName: elt.currentTarget.selectedOptions[0].value,
            page : this.findPagebyName(this.state.pages, elt.currentTarget.selectedOptions[0].value)
        });
        
    };

    private loadData = async (siteName: string) => {
        const { apiAuditUrl } = getConfiguration();
        try {
            const metadata = await fetcher<AuditMetadata[]>(
                `${apiAuditUrl}/v1/pages/${siteName}`,
                'GET'
            );
            this.setState({
                pages: metadata,
                selectedPageName: metadata[0].name,
                page: metadata[0]
            });
            
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    private handleSubmit = (event: any) => {
        event.preventDefault();
        this.CreateAuditPage();

        return false;
    };
    private handleAddPageSubmit = (event: any) => {
        event.preventDefault();
        if (this.state.inputPageName !== "" && this.state.inputPageUrl != "") {
            this.AddPageSubmit();
        }
        else {
            this.setState({
                dataCreateError: '',
                createAuditPageSuccess: 7,
            });
        }

        return false;
    };
    private AddPageSubmit = async () => {
        if (this.state.page != undefined)
        {
            this.setState({
                isAddPageLoading: true
            });
            const { apiAuditUrl } = getConfiguration();
            try {
                await sendForm<any>(
                    `${apiAuditUrl}/v1/pages`,
                    'POST',
                    `{ "siteName": "${this.state.page.siteName}", "name": "${this.state.inputPageName}", "url": "${this.state.inputPageUrl}"}`
                ).then(async () => {
                    this.setState({ dataCreateError: '', createAuditPageSuccess: 5 } );
                    if (this.state.page != undefined)
                    {
                        await this.loadData(this.state.page.siteName);
                    }
                });
            } catch (error) {
                if (error as HttpError) {
                    const httpError = error as HttpError;
                    this.setState({
                        dataCreateError: transformHttpErrorToErrorMessage(
                            httpError
                        ),
                        createAuditPageSuccess: 6,
                    });
                    logTechnical('error', httpError.message, {
                        stack: httpError.stack || '',
                    });
                }
            }
            finally {
                this.setState({
                    isAddPageLoading: false,
                    addPageVisible: false
                });
            }
        }
        return false;
    };
    private DeletePageSubmit = async (siteName: string, pageName: string) => {
        if (this.state.page != undefined)
        {
            this.setState({
                isAddPageLoading: true
            });
            const { apiAuditUrl } = getConfiguration();
            try {
                await sendForm<any>(
                    `${apiAuditUrl}/v1/pages`,
                    'DELETE',
                    `{ "siteName": "${siteName}", "pageName": "${pageName}"}`
                ).then(async () => {
                    this.setState({ dataCreateError: '', createAuditPageSuccess: 8 } );
                    if (this.state.page != undefined)
                    {
                        await this.loadData(this.state.page.siteName);
                    }
                });
            } catch (error) {
                if (error as HttpError) {
                    const httpError = error as HttpError;
                    this.setState({
                        dataCreateError: transformHttpErrorToErrorMessage(
                            httpError
                        ),
                        createAuditPageSuccess: 9,
                    });
                    logTechnical('error', httpError.message, {
                        stack: httpError.stack || '',
                    });
                }
            }
            finally {
                this.setState({
                    isAddPageLoading: false
                });
            }
        }
        return false;
    };
    private handleDeletePageSubmit = () => {
        if (this.state.pages.length > 1)
        {
            this.setState({
                dataCreateError: '',
                createAuditPageSuccess: 10,
            });
        }
        if (this.state.page != undefined) 
        {
            this.DeletePageSubmit(this.state.page.siteName, this.state.page.name);
        }
        return false;
    };
    private collapseAddPage = () => {
        const visible = !this.state.addPageVisible;
        
        this.setState({
            addPageVisible: visible
        });
        return false;
    };
    
    private submitAction = (action: number) => {
        
        this.setState({
            submitedAction: action,
        });
        return action;
    }
    private updateRule = (page: any, rule: any, elt: any) => {
        this.setState({
            page: page,
        });
        return false;
    };
    

    private CreateAuditPage = async () => {
        const { apiAuditUrl } = getConfiguration();
        if (this.state.submitedAction == 2) {
            try {
                await sendForm<any>(
                    `${apiAuditUrl}/v1/pages`,
                    'POST',
                    JSON.stringify(this.state.page)
                );
                this.setState({ dataCreateError: '', createAuditPageSuccess: 1 }
                );
            } catch (error) {
                if (error as HttpError) {
                    const httpError = error as HttpError;
                    this.setState({
                        dataCreateError: transformHttpErrorToErrorMessage(
                            httpError
                        ),
                        createAuditPageSuccess: 2,
                    });
                    logTechnical('error', httpError.message, {
                        stack: httpError.stack || '',
                    });
                }
            }
        }
        else if (this.state.submitedAction == 1 && this.state.page != undefined) {
            
            this.setState({
                isLoading: true
            });
            try {
                await sendForm<any>(
                    `${apiAuditUrl}/v1/rgaa/do_compute`,
                    'POST',
                    `{ "siteName": "${this.state.page.siteName}", "pageName": "${this.state.page.name}"}`
                );
                this.setState({ dataCreateError: '', createAuditPageSuccess: 3 }
                );
            } catch (error) {
                if (error as HttpError) {
                    const httpError = error as HttpError;
                    this.setState({
                        dataCreateError: transformHttpErrorToErrorMessage(
                            httpError
                        ),
                        createAuditPageSuccess: 4,
                    });
                    logTechnical('error', httpError.message, {
                        stack: httpError.stack || '',
                    });
                }
            }
            finally {
                await this.loadData(this.state.page.siteName);
            }
        }
    };

}
const transformHttpErrorToErrorMessage = (httpError: HttpError): string => {
    const defaultMessage = 'Please fill all fields';
    if (httpError.statusCode === 400) {
        return (
            (httpError.content.errors
                ? transformErrorMessageToString(httpError.content.errors)
                : httpError.content.message) || defaultMessage
        );
    }
    return httpError.content.message || defaultMessage;
};
const transformErrorMessageToString = (content: {
    [name: string]: string;
}): string => {
    const result = [];
    for (const [key, value] of Object.entries(content)) {
        result.push(`${key}: ${value}`);
    }
    return result.join(', ');
};

export {
    CreateAuditPageProvider,
    Consumer as CreateAuditPageConsumer,
    context as CreateAuditPageContext,
};
