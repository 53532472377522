import React from 'react';
import { getConnectedUserFromBus } from '../../../src/core/service/ConnectedUserPrivilege';
import { getConfiguration } from '../../core/configuration/configurationLoader';
import { fetcher } from '../../core/http/fetcher';
import { logTechnical } from '../../core/logging/logger';
import { AuthenticatedUser } from '../../core/models/authenticatedUser';

const defaultUser = null as AuthenticatedUser | null;

const initialState = {
    authenticatedUser: defaultUser,
    isLoading: true,
};

type AuthenticationProviderState = Readonly<typeof initialState>;

export type AuthenticationContextState = AuthenticationProviderState;

const context = React.createContext<AuthenticationContextState>(initialState);
const { Provider, Consumer } = context;

class AuthenticationProvider extends React.Component<
    {},
    AuthenticationProviderState
> {
    public readonly state: AuthenticationProviderState = initialState;

    public async componentDidMount() {
        const { apiContentUrl } = getConfiguration();
        try {
            const userInformation = await fetcher<AuthenticatedUser>(
                `${apiContentUrl}/v1/FrontEnd/connecteduser`,
                'GET'
            );

            getConnectedUserFromBus((result: string) => {
                this.setState(
                    updateAuthenticatedUser(userInformation, result === 'LAN'),
                    () => this.setState({ isLoading: false })
                );
            });
        } catch (error) {
            logTechnical('error', error.message, { stack: error.stack || '' });
            this.setState({ authenticatedUser: null, isLoading: false });
        }
    }

    public render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }
}

const updateAuthenticatedUser = (
    userInformation: AuthenticatedUser,
    isFromLan: boolean
) => (prevState: AuthenticationContextState): AuthenticationContextState => {
    if (userInformation) {
        return {
            ...prevState,
            authenticatedUser: {
                ...userInformation,
                isConnectedFromLan: isFromLan,
            },
        };
    }

    return prevState;
};

export {
    AuthenticationProvider,
    Consumer as AuthenticationConsumer,
    context as AuthenticationContext,
};
