import 'es5-shim';
import 'es6-shim';
import 'react-app-polyfill/ie11';
import 'core-js/stable/array/includes';

import { SGWTConnectCore } from '@sgwt/connect-core';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {
    getConfiguration,
    loadConfiguration,
} from './core/configuration/configurationLoader';
import {
    getSGConnect,
    loadSGConnect,
    setupPassiveMode,
} from './core/sgConnect';
import './index.css';
import * as serviceWorker from './serviceWorker';

type AppWindow = {
    sgwtConnect: SGWTConnectCore;
    SGWTWidgetConfiguration: { environment: 'homologation' | 'production' };
};

const startup = async () => {
    loadConfiguration(window.location.hostname);
    loadSGConnect(getConfiguration().sgConnect);

    const sgConnect = getSGConnect();
    if (!sgConnect.isAuthorized()) {
        sgConnect.requestAuthorization();
        return;
    }
    const windowIfDefined =
        typeof window === 'undefined'
            ? null
            : ((window as unknown) as AppWindow);
    if (windowIfDefined && !windowIfDefined.sgwtConnect) {
        windowIfDefined.sgwtConnect = sgConnect;
    }
    setupPassiveMode();

    ReactDOM.render(<App />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
};

startup();
